@import '../abstracts/variables';

#img-avatarInJungle {
  color: white;
  position: relative;

  #main-title {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 0.3em;
    text-shadow: 4px 3px 0 #7a7a7a;
    z-index: -4;
  }

  .img-avatar {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
  }

  #layer_bg {
    z-index: -8;
  }

  #layer_0 {
    z-index: -5;
  }

  #layer_1 {
    z-index: -6;
  }

  #layer_2 {
    z-index: -4;
  }
  #gradient-linear {
    object-fit: cover;
    width: 100%;
    height: 102vh;
    position: absolute;
    left: 0;
    background: linear-gradient(to top, #14352e, transparent 30vh);
  }
}
#bg-presentation {
  height: 110vh;
  position: absolute;
  bottom: -110vh;
  background: linear-gradient(
    to bottom,
    #14352e 5%,
    #14352e88 70%,
    #14352e57 85%,
    transparent
  );
  z-index: -1;
}
#presentation {
  margin-bottom: 15vh;
  height: 100vh;
  position: relative;
  color: white;
  #content-presentation {
    text-align: center;
    .spock-icon {
      position: absolute;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    }
    .spock-icon:first-child {
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .spock-icon:nth-of-type(2) {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    blockquote {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      p {
        font-family: 'Merriweather', serif;
        font-weight: lighter;
        margin: 0 auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        span {
          font-weight: 900;
        }
      }
    }
    .border-presentation {
      border: wheat solid 4px;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      opacity: 25%;
    }
    #cube-1 {
      transform: translate(-50%, -50%) rotateZ(26deg);
    }
    #cube-2 {
      transform: translate(-50%, -50%) rotateZ(45deg);
    }
  }
}

/**************** Mobile style *****************/
/**
* xs
**/
@media screen and (min-width: $breakpoint-min-xs) and (max-width: $breakpoint-max-xs) {
  #main-title {
    font-size: 12.5vmin;
    top: 1em;
  }
  #presentation {
    #content-presentation {
      padding: 15rem 0;
      max-width: 35em;
      margin: 0 auto;
      .border-presentation {
        height: 12rem;
        width: 12rem;
      }
      blockquote {
        max-width: 18em;
        min-width: 15em;
        p {
          font-size: 0.8rem;
          line-height: 30px;
        }
      }
      .spock-icon {
        font-size: 1rem;
      }
      .spock-icon:first-child {
        top: 10%;
      }
      .spock-icon:nth-of-type(2) {
        top: 90%;
      }
    }
  }
}
/**
* sm
**/
@media screen and (min-width: $breakpoint-min-sm) and (max-width: $breakpoint-max-sm) {
  #main-title {
    font-size: 6vmax;
    top: 0.5em;
  }
  #presentation {
    #content-presentation {
      padding: 15rem 0;
      max-width: 35em;
      margin: 0 auto;
      .border-presentation {
        height: 15rem;
        width: 15rem;
      }
      blockquote {
        max-width: 25em;
        min-width: 20em;
        p {
          font-size: 0.8rem;
          line-height: 40px;
        }
      }
      .spock-icon {
        font-size: 1rem;
      }
      .spock-icon:first-child {
        top: 20%;
      }
      .spock-icon:nth-of-type(2) {
        top: 80%;
      }
    }
  }
}
/**
* md
**/
@media screen and (min-width: $breakpoint-min-md) and (max-width: $breakpoint-max-md) {
  #main-title {
    font-size: 6vmax;
    top: 0.5em;
  }
  #presentation {
    #content-presentation {
      padding: 15rem 0;
      max-width: 35em;
      margin: 0 auto;
      .border-presentation {
        height: 25rem;
        width: 25rem;
      }
      blockquote {
        max-width: 30em;
        min-width: 25em;
        p {
          font-size: 1.1rem;
          line-height: 40px;
        }
      }
      .spock-icon {
        font-size: 1rem;
      }
      .spock-icon:first-child {
        top: 20%;
      }
      .spock-icon:nth-of-type(2) {
        top: 80%;
      }
    }
  }
}

/**************** Laptop style *****************/
@media screen and (min-width: $breakpoint-min-laptop) {
  #main-title {
    font-size: 7vmax;
    top: 0.5em;
  }
  #presentation {
    #content-presentation {
      padding: 15rem 0;
      max-width: 35em;
      margin: 0 auto;
      .border-presentation {
        height: 25rem;
        width: 25rem;
      }
      blockquote {
        max-width: 30em;
        min-width: 25em;
        p {
          font-size: 1.1rem;
          line-height: 40px;
        }
      }
      .spock-icon {
        font-size: 1rem;
      }
      .spock-icon:first-child {
        top: 20%;
      }
      .spock-icon:nth-of-type(2) {
        top: 80%;
      }
    }
  }
}

/**************** Desktop style *****************/
@media screen and (min-width: $breakpoint-min-desktop) {
  #main-title {
    font-size: 10vmax;
    top: 0.5em;
  }
  #presentation {
    #content-presentation {
      padding: 15rem 0;
      max-width: 35em;
      margin: 0 auto;
      .border-presentation {
        height: 25rem;
        width: 25rem;
      }
      blockquote {
        max-width: 45em;
        min-width: 35em;
        p {
          font-size: 1.2rem;
          line-height: 40px;
        }
      }
      .spock-icon {
        font-size: 1rem;
      }
      .spock-icon:first-child {
        top: 20%;
      }
      .spock-icon:nth-of-type(2) {
        top: 80%;
      }
    }
  }
}

/**************** 4k style *****************/
@media screen and (min-width: $breakpoint-min-4k) {
  #main-title {
    font-size: 10vmax;
    top: 0.5em;
  }
  #presentation {
    #content-presentation {
      padding: 15rem 0;
      max-width: 35em;
      margin: 0 auto;
      .border-presentation {
        height: 38rem;
        width: 38rem;
      }
      blockquote {
        max-width: 45em;
        min-width: 35em;
        p {
          font-size: 1.8rem;
          line-height: 40px;
        }
      }
      .spock-icon {
        font-size: 1.5rem;
      }
      .spock-icon:first-child {
        top: 10%;
      }
      .spock-icon:nth-of-type(2) {
        top: 90%;
      }
    }
  }
}
