.page-error{
    width: 100%;
    height: 100vh;
    #wrapper{
        width: 70%;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        gap: 4rem;
        .container-title{
            animation: sliding-background 40s linear infinite;
            background: url("https://images.unsplash.com/photo-1452697620382-f6543ead73b5") no-repeat left / 120%;
            text-align: center;
            div{
                margin-top: -2px;
                margin-left: -2px;
                .title {
                    font-size: max(10vw, 4em, 80px);
                    height: 250px;
                    overflow: hidden;
                    background: white;
                    color: black;
                    font-family: "Montserrat", sans-serif;
                    letter-spacing: 1vw;
                    margin: 0;
                    mix-blend-mode: lighten;
                    $text-shadow: ();
                    @for $i from 1 through 16 {
                        $text-shadow: $text-shadow, 1px * $i 1px * $i black;
                    }
                    text-shadow: 0 0 0px, $text-shadow;
                
                    &:before {
                        color: white;
                        content: attr(data-text);
                        position: absolute;
                    }
                    @keyframes sliding-background {
                        0%,
                        100% {
                            background-position: 0 40%;
                        }
                    
                        50% {
                            background-position: 100% 40%;
                        }
                    }
                }
            }
        } 
        .content-page-error{
            h2{
                font-weight: bold;
                font-size: max(2vw, 2em);
            }
            p{
                font-weight: 100;
                font-size: max(1vw, 1em);
            }
        } 
        .btn-home{
            button{
                background-color: #4b8cfb;
                border: none;
                color: white;
                padding: 15px 32px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: max(1vw, 1em);
                border-radius: 50px;
                -webkit-box-shadow: 13px 14px 25px -7px rgba(0,0,0,0.41); 
                box-shadow: 13px 14px 25px -7px rgba(0,0,0,0.41);
                &:hover{
                    transform: translateY(3px);
                    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
                    0 1px 1px 0 rgba(0,0,0,0.09);
                }
            }
        }
    }
}
