@import '../abstracts/variables';

#content-sidebar {
  position: fixed;
  background-color: #aed4a5;
  height: 100%;
}

/****************************************
*****************HEADER******************
*****************************************/

#header-home {
  padding: 0.5em;
  ul {
    list-style: none;
    li {
      a {
        color: #333333;
        letter-spacing: 2px;
        &:hover {
          color: white;
          font-weight: 900;
        }
        &.active-link {
          color: white;
          font-weight: 900;
        }
      }
    }
    #line-link{
      display: flex;
      gap: 5px;
    }
  }
  #sidebar-header {
    text-align: center;
    h3 {
      font-weight: bold;
    }
    h4 {
      color: #333333;
    }
    h5 {
      font-weight: lighter;
    }
  }
}

/****************************************
******************OWL********************
*****************************************/
#wrapper_owl {
  // background-color: darksalmon;
  position: absolute;
  #img-owl {
    // background-color:blueviolet;
    width: 40vmin;
  }
}

/****************************************
*****************FOOTER******************
*****************************************/

#infos-contact {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 4rem;
  #content {
    padding: 0.8rem 0;
    #mail {
      font-weight: 600;
    }
  }
}

/**************** Mobile style *****************/
/**
* xs
**/
@media screen and (min-width: $breakpoint-min-xs) {
  #content-sidebar {
    height: 100%;
    width: 100%;
    header {
      top: 0;
      position: fixed;
      width: 70vw;
      ul {
        list-style: none;
        padding: 0.5rem;
        li {
          padding-top: 0.5em;
          a {
            font-size: 1.3rem;
          }
        }
      }

      #sidebar-header {
        padding-top: 1rem;
        h3 {
          font-size: max(4vmax, 3rem);
        }
        h4 {
          font-size: 0.6em;
        }
        h5 {
          font-size: 0.4em;
        }
      }
    }

    #wrapper_owl {
      display: none;
      position: fixed;
      top: 65%;
      #img-owl {
        // background-color:blueviolet;
        width: 45vw;
      }
    }

    #infos-contact {
      bottom: 0;
      width: 79vmin;
      .social-icon {
        margin-right: 0.3rem;
        padding: 0.4rem 0.6rem;
      }
      #content {
        margin: 1.2rem auto;
        border: black solid 0.2em;
        margin-left: 0.5rem;
        #mail {
          font-size: max(2vmax, 1.3rem);
        }
      }
    }
  }
}
/**
* sm
**/
@media screen and (min-width: $breakpoint-min-sm) and (max-width: $breakpoint-max-sm) {
  #content-sidebar {
    height: 100%;
    width: 100%;
    header {
      top: 0;
      position: fixed;
      width: 44vw;
      ul {
        list-style: none;
        padding: 0.5rem;
        margin-top: 1rem;
        li {
          padding-top: 1.2em;
          a {
            font-size: 1.1rem;
          }
        }
      }

      #sidebar-header {
        padding-top: 2rem;
        h3 {
          font-size: 4vmax;
        }
        h4 {
          font-size: 0.7em;
        }
        h5 {
          font-size: 0.5em;
        }
      }
    }

    #wrapper_owl {
      position: fixed;
      top: 65%;
      #img-owl {
        // background-color:blueviolet;
        width: 35vw;
      }
    }

    #infos-contact {
      bottom: 0;
      width: 49vmin;
      .social-icon {
        font-size: 1em;
        padding: 0.4rem 0.6rem;
      }
      #content {
        margin: 1.2rem auto;
        border: black solid 0.2em;
        margin-left: 0.5rem;
        #mail {
          font-size: 1.4vmax;
        }
      }
    }
  }
}
/**
* md
**/
@media screen and (min-width: $breakpoint-min-md) and (max-width: $breakpoint-max-md) {
  #content-sidebar {
    height: 100%;
    width: 100%;
    header {
      top: 0;
      position: fixed;
      width: 36vw;
      ul {
        list-style: none;
        padding: 0.5rem;
        margin-top: 1rem;
        li {
          padding-top: 1.5em;
          a {
            font-size: 1.4rem;
          }
        }
      }

      #sidebar-header {
        padding-top: 2rem;
        h3 {
          font-size: 4vmax;
        }
        h4 {
          font-size: 0.7em;
        }
        h5 {
          font-size: 0.5em;
        }
      }
    }

    #wrapper_owl {
      position: fixed;
      top: 65%;
      #img-owl {
        // background-color:blueviolet;
        width: 20vw;
      }
    }

    #infos-contact {
      bottom: 0;
      width: 39vmin;
      .social-icon {
        font-size: 1em;
        padding: 0.4rem 0.6rem;
      }
      #content {
        margin: 1.2rem auto;
        border: black solid 0.3em;
        margin-left: 0.5rem;
        #mail {
          font-size: 1.4vmax;
        }
      }
    }
  }
}

/**************** Laptop style *****************/
@media screen and (min-width: $breakpoint-min-laptop) {
  #content-sidebar {
    height: 100%;
    width: 25%;
    header {
      top: 0;
      position: fixed;
      width: 23vw;
      ul {
        list-style: none;
        padding: 0.5rem;
        margin-top: 1rem;
        li {
          padding-top: 1.5em;
          a {
            font-size: max(2vw, 1rem);
          }
        }
      }

      #sidebar-header {
        padding-top: 2rem;
        h3 {
          font-size: 3.2vmax;
        }
        h4 {
          font-size: 1.2em;
        }
        h5 {
          font-size: 0.8em;
        }
      }
    }

    #wrapper_owl {
      position: fixed;
      top: 55%;
      #img-owl {
        // background-color:blueviolet;
        width: 15vw;
      }
    }

    #infos-contact {
      bottom: 0;
      position: fixed;
      width: 25%;
      text-align: center;
      padding-bottom: 1rem;
      font-size: 1.5rem;
      .social-icon {
        font-size: 1em;
        padding: 0.4rem 0.6rem;
      }
      #content {
        margin: 1.2rem;
        border: black solid 0.2em;
        #mail {
          font-size: 1.4vmax;
        }
      }
    }
  }
}

/**************** Desktop style *****************/
@media screen and (min-width: $breakpoint-min-desktop) {
  #content-sidebar {
    height: 100%;
    width: 25%;
    header {
      top: 0;
      position: fixed;
      width: 23vw;
      ul {
        list-style: none;
        padding: 0.5rem;
        margin-top: 1rem;
        li {
          padding-top: max(4vh, 1.5em);
          a {
            font-size: max(1.6vw, 1.4rem);
          }
        }
      }

      #sidebar-header {
        padding-top: 2rem;
        h3 {
          font-size: 3.5vmax;
        }
        h4 {
          font-size: 1.5em;
        }
        h5 {
          font-size: 1em;
        }
      }
    }

    #wrapper_owl {
      position: fixed;
      top: 55%;
      #img-owl {
        // background-color:blueviolet;
        width: 15vw;
      }
    }

    #infos-contact {
      bottom: 0;
      position: fixed;
      width: 25%;
      text-align: center;
      padding-bottom: 1rem;
      font-size: 1.5rem;
      .social-icon {
        font-size: 1.5em;
        padding: 0.4rem 0.6rem;
      }
      #content {
        margin: 1.2rem;
        border: black solid 0.2em;
        #mail {
          font-size: 1.4vmax;
        }
      }
    }
  }
}

/**************** 4k style *****************/
@media screen and (min-width: $breakpoint-min-4k) {
  #content-sidebar {
    height: 100%;
    width: 20%;
    header {
      top: 0;
      position: fixed;
      width: 19vw;
      ul {
        list-style: none;
        padding: 0.5rem;
        margin-top: 1rem;
        li {
          padding-top: max(4vh, 1.5em);
          a {
            font-size: max(1.6vw, 1.6rem);
          }
        }
      }

      #sidebar-header {
        padding-top: 2rem;
        h3 {
          font-size: 3.5vmax;
        }
        h4 {
          font-size: 2em;
        }
        h5 {
          font-size: 1.5em;
        }
      }
    }

    #wrapper_owl {
      position: fixed;
      top: 55%;
      #img-owl {
        // background-color:blueviolet;
        width: 15vw;
      }
    }

    #infos-contact {
      bottom: 0;
      position: fixed;
      width: 20%;
      text-align: center;
      padding-bottom: 1rem;
      font-size: 1.5rem;
      .social-icon {
        font-size: 1.5em;
        padding: 0.4rem 0.6rem;
      }
      #content {
        margin: 1.2rem;
        border: black solid 0.3em;
        #mail {
          font-size: 1.4vmax;
        }
      }
    }
  }
}
