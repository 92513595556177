@import '../abstracts/variables';

#container-footer-project {
  margin-top: 20vh;
}

#container-footer-layout {
  margin-top: 100vh;
}

footer {
  background: linear-gradient(360deg, #afd4a5 30%, white 100%);
  position: relative;
  min-height: 9rem;
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
      margin: 0 0.5rem;
      letter-spacing: 2px;
    }
  }
  #copyright {
    letter-spacing: 2px;
    font-weight: 100;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/**************** Mobile style *****************/
/**
* xs
**/
@media screen and (min-width: $breakpoint-min-xs) and (max-width: $breakpoint-max-xs) {
  footer {
    div {
      top: 75%;
      span {
        font-size: 2rem;
      }
      p {
        font-size: 0.7rem;
      }
    }
    #copyright {
      top: 88%;
      font-size: 0.6rem;
    }
  }
}
/**
* sm
**/
@media screen and (min-width: $breakpoint-min-sm) and (max-width: $breakpoint-max-sm) {
  footer {
    div {
      top: 75%;
      span {
        font-size: 2rem;
      }
      p {
        font-size: 1em;
      }
    }
    #copyright {
      top: 88%;
      font-size: 0.7rem;
    }
  }
}
/**
* md
**/
@media screen and (min-width: $breakpoint-min-md) and (max-width: $breakpoint-max-md) {
  footer {
    div {
      top: 75%;
      span {
        font-size: 2rem;
      }
      p {
        font-size: 1em;
      }
    }
    #copyright {
      top: 88%;
      font-size: 0.7rem;
    }
  }
}

/**************** Laptop style *****************/
@media screen and (min-width: $breakpoint-min-laptop) and (max-width: $breakpoint-max-laptop) {
  footer {
    div {
      top: 75%;
      span {
        font-size: 2rem;
      }
      p {
        font-size: 1.2em;
      }
    }
    #copyright {
      top: 88%;
      font-size: 0.8rem;
    }
  }
}

/**************** Desktop style *****************/
@media screen and (min-width: $breakpoint-min-desktop) {
  footer {
    div {
      top: 75%;
      span {
        font-size: 2rem;
      }
      p {
        font-size: 1.2em;
      }
    }
    #copyright {
      top: 88%;
      font-size: 0.8rem;
    }
  }
}

/**************** 4k style *****************/
@media screen and (min-width: $breakpoint-min-4k) and (max-width: $breakpoint-max-4k) {
  footer {
    div {
      top: 75%;
      span {
        font-size: 2rem;
      }
      p {
        font-size: 1.2em;
      }
    }
    #copyright {
      top: 88%;
      font-size: 0.8rem;
    }
  }
}
