@import 'base/reset';
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100;300;400;500;700;800;900&family=Rajdhani&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');

@import 'components/icons';

@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'layouts/sidebar';
@import 'layouts/presentation';
@import 'layouts/expertise';
@import 'layouts/experience';
@import 'layouts/contact';
@import 'layouts/footer';
@import 'layouts/project-details';
@import 'layouts/error-page';

html {
  scroll-behavior: smooth;
  font-family: 'MPLUS1', Arial, Helvetica, sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  a {
    text-decoration: none;
    color: black;
    cursor: pointer;
  }

  #wrapper {
    #test-media {
      height: 900px;
    }
    /****************************************
        *****************SIDEBAR******************
        *****************************************/
    .burger {
      cursor: pointer;
      z-index: 20;
      position: fixed;
      top: 0.5rem;
      left: 0.5rem;
      mix-blend-mode: difference;
      span {
        width: 100%;
        background-color: white;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        transition: background-color 0.55s ease;
        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          transition: background-color 0.55s ease;
          background-color: white;
        }
      }
      &.is-toggled {
        mix-blend-mode: normal;
        span {
          background-color: transparent;
          &::before {
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              background-color 0.55s ease;
            transform: translateY(0) rotate(-45deg);
            background-color: black;
          }
          &::after {
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              background-color 0.55s ease;
            transform: translateY(0) rotate(45deg);
            background-color: black;
          }
        }
      }
    }

    .sidebar {
      color: #333333;
      opacity: 0.97;
    }
    /****************************************
        *****************MAIN CONTENT************
        *****************************************/
    #container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      #second-bg-on-top {
        position: absolute;
        height: 200vh;
        background: linear-gradient(to bottom, #14352e 100vh, white 132vh);
        z-index: -20;
      }

      #wrapper-content {
        position: relative;
        bottom: -100vh;
        .title-section {
          animation: sliding-background 40s linear infinite;
          background: url('https://images.unsplash.com/photo-1452697620382-f6543ead73b5')
            no-repeat left / 120%;
          text-align: center;
          div {
            margin-top: -2px;
            margin-left: -2px;
            .title {
              // border: #3894fc solid 20px;
              overflow: hidden;
              background: white;
              color: black;
              font-family: 'Montserrat', sans-serif;
              letter-spacing: 1vw;
              margin: 0;
              mix-blend-mode: lighten;
              text-transform: uppercase;
              $text-shadow: ();
              @for $i from 1 through 16 {
                $text-shadow: $text-shadow, 1px * $i 1px * $i black;
              }
              text-shadow: 0 0 0px, $text-shadow;

              &:before {
                color: white;
                content: attr(data-text);
                position: absolute;
              }
              @keyframes sliding-background {
                0%,
                100% {
                  background-position: 0 40%;
                }

                50% {
                  background-position: 100% 40%;
                }
              }
            }
          }
        }
      }
    }
  }
}

/**************** Mobile style *****************/
/**
* xs
**/
@media screen and (min-width: $breakpoint-min-xs) and (max-width: $breakpoint-max-xs) {
  body {
    #wrapper {
      width: 100%;
      height: 100%;
      #bg-presentation {
        width: 100%;
      }
      #second-bg-on-top {
        width: 100%;
      }
      #wrapper-sidebar {
        z-index: 15;
        position: fixed;
        top: 0;
        left: -100.005%;
        bottom: 0;
        width: 80%;
        font-size: 1.8rem;

        @include transitionAll();
        &.is-collapsed {
          transition: transform 0.3s ease-in-out;
          transform: translateX(125%);
        }
      }
      #wrapper-content {
        max-width: 76vw;
        margin: 0 auto;
        .title-section {
          .title {
            font-size: 10vw;
          }
        }
        .infos-section {
          padding: 3rem 0;
          p {
            font-size: 1em;
            font-weight: 100;
          }
        }
        .subTitle-section {
          padding: 8rem 0;
          h3 {
            font-size: 2em;
            font-weight: 900;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 100px;
              height: 3px;
              background: #149ddd;
              bottom: -25px;
              left: 0;
            }
          }
        }
      }
      .burger {
        width: 1.7rem;
        height: 24px;
        span {
          height: 4px;
          &::before,
          &::after {
            height: 4px;
          }
          &::before {
            transform: translateY(-8px);
          }
          &::after {
            transform: translateY(8px);
          }
        }
      }
    }
  }
}
/**
* sm
**/
@media screen and (min-width: $breakpoint-min-sm) and (max-width: $breakpoint-max-sm) {
  body {
    #wrapper {
      width: 100%;
      height: 100%;
      #bg-presentation {
        width: 100%;
      }
      #second-bg-on-top {
        width: 100%;
      }
      #wrapper-sidebar {
        z-index: 15;
        position: fixed;
        top: 0;
        left: -100.005%;
        bottom: 0;
        width: 50%;
        font-size: 1.8rem;

        @include transitionAll();
        &.is-collapsed {
          transition: transform 0.3s ease-in-out;
          transform: translateX(200%);
        }
      }
      #wrapper-content {
        max-width: 80vw;
        margin: 0 auto;
        .title-section {
          .title {
            font-size: 10vw;
          }
        }
        .infos-section {
          padding: 3rem 0;
          p {
            font-size: 0.8em;
            font-weight: 100;
          }
        }
        .subTitle-section {
          padding: 8rem 0;
          h3 {
            font-size: 2em;
            font-weight: 900;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 100px;
              height: 3px;
              background: #149ddd;
              bottom: -25px;
              left: 0;
            }
          }
        }
      }
      .burger {
        width: 1.7rem;
        height: 24px;
        span {
          height: 4px;
          &::before,
          &::after {
            height: 4px;
          }
          &::before {
            transform: translateY(-8px);
          }
          &::after {
            transform: translateY(8px);
          }
        }
      }
    }
  }
}
/**
* md
**/
@media screen and (min-width: $breakpoint-min-md) and (max-width: $breakpoint-max-md) {
  body {
    #wrapper {
      width: 100%;
      height: 100%;
      #bg-presentation {
        width: 100%;
      }
      #second-bg-on-top {
        width: 100%;
      }
      #wrapper-sidebar {
        z-index: 15;
        position: fixed;
        top: 0;
        left: -100.005%;
        bottom: 0;
        width: 40%;
        font-size: 1.8rem;

        @include transitionAll();
        &.is-collapsed {
          transition: transform 0.3s ease-in-out;
          transform: translateX(250%);
        }
      }
      #wrapper-content {
        max-width: 75vw;
        margin: 0 auto;
        .title-section {
          .title {
            font-size: 9vw;
          }
        }
        .infos-section {
          padding: 3rem 0;
          p {
            font-size: 0.9em;
            font-weight: 100;
          }
        }
        .subTitle-section {
          padding: 8rem 0;
          h3 {
            font-size: 3em;
            font-weight: 900;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 100px;
              height: 3px;
              background: #149ddd;
              bottom: -25px;
              left: 0;
            }
          }
        }
      }
      .burger {
        width: 1.7rem;
        height: 24px;
        span {
          height: 4px;
          &::before,
          &::after {
            height: 4px;
          }
          &::before {
            transform: translateY(-8px);
          }
          &::after {
            transform: translateY(8px);
          }
        }
      }
    }
  }
}

/**************** Laptop style *****************/
@media screen and (min-width: $breakpoint-min-laptop) {
  #wrapper {
    width: 100%;
    height: 100%;
    display: flex;

    #wrapper-sidebar {
      flex: 0 0 25%;
    }
    #img-avatarInJungle {
      flex: 1 1 75%;
    }
    #bg-presentation {
      width: 75%;
    }
    #second-bg-on-top {
      width: 75%;
    }
    #wrapper-content {
      flex: 1 1 75%;
      max-width: 60vw;
      margin: 0 auto;
      .title-section {
        .title {
          font-size: 7vw;
        }
      }
      .infos-section {
        padding: 3rem 0;
        p {
          font-size: 1em;
          font-weight: 100;
        }
      }
      .subTitle-section {
        padding: 8rem 0;
        h3 {
          font-size: 3em;
          font-weight: 900;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100px;
            height: 3px;
            background: #149ddd;
            bottom: -25px;
            left: 0;
          }
        }
      }
    }
    .burger {
      display: none;
    }
  }
}

/**************** Desktop style *****************/
@media screen and (min-width: $breakpoint-min-desktop) {
  #wrapper {
    width: 100%;
    height: 100%;
    display: flex;

    #wrapper-sidebar {
      flex: 0 0 25%;
    }
    #img-avatarInJungle {
      flex: 1 1 75%;
    }
    #bg-presentation {
      width: 75%;
    }
    #second-bg-on-top {
      width: 75%;
    }
    #wrapper-content {
      flex: 1 1 75%;
      max-width: 60vw;
      margin: 0 auto;
      .title-section {
        .title {
          font-size: 7vw;
        }
      }
      .infos-section {
        padding: 3rem 0;
        p {
          font-size: 1.5em;
          font-weight: 100;
        }
      }
      .subTitle-section {
        padding: 8rem 0;
        h3 {
          font-size: 3em;
          font-weight: 900;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100px;
            height: 3px;
            background: #149ddd;
            bottom: -25px;
            left: 0;
          }
        }
      }
    }
    .burger {
      display: none;
    }
  }
}

/**************** 4k style *****************/
@media screen and (min-width: $breakpoint-min-4k) {
  #wrapper {
    width: 100%;
    height: 100%;
    display: flex;

    #wrapper-sidebar {
      flex: 0 0 20%;
    }
    #img-avatarInJungle {
      flex: 1 1 80%;
    }
    #bg-presentation {
      width: 80%;
    }
    #second-bg-on-top {
      width: 80%;
    }
    #wrapper-content {
      flex: 1 1 80%;
      max-width: 60vw;
      margin: 0 auto;
      .title-section {
        .title {
          font-size: 7vw;
        }
      }
      .infos-section {
        padding: 3rem 0;
        p {
          font-size: 1.5em;
          font-weight: 100;
        }
      }
      .subTitle-section {
        padding: 8rem 0;
        h3 {
          font-size: 3em;
          font-weight: 900;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100px;
            height: 3px;
            background: #149ddd;
            bottom: -25px;
            left: 0;
          }
        }
      }
    }
    .burger {
      display: none;
    }
  }
}
