@font-face {
  font-family: 'MPLUS1';
  src: url(../../assets/fonts/MPLUS1p-Regular.ttf) format('truetype');
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'MPLUS1';
  src: url(../../assets/fonts/MPLUS1p-Bold.ttf) format('truetype');
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: 'MPLUS1';
  src: url(../../assets/fonts/MPLUS1p-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: 'MPLUS1';
  src: url(../../assets/fonts/MPLUS1p-Black.ttf) format('truetype');
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'MPLUS1';
  src: url(../../assets/fonts/MPLUS1p-Light.ttf) format('truetype');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'MPLUS1';
  src: url(../../assets/fonts/MPLUS1p-Medium.ttf) format('truetype');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'MPLUS1';
  src: url(../../assets/fonts/MPLUS1p-Thin.ttf) format('truetype');
  font-weight: 100;
  font-display: swap;
}
