@import '../abstracts/variables';

#contact {
  background-color: white;
  min-height: 40rem;
  position: relative;
  padding-bottom: 7em;
  #content-contact {
    #form-container {
      width: 100%;
      background-image: url('../../images/flowers-bg-600.png');
      background-position: 0 95%;
      background-repeat: no-repeat;
      #flowers-right {
        position: absolute;
        top: 0;
        right: 1em;
      }
      #title {
        padding: 4rem 0;
        text-align: center;
        h3 {
          font-weight: 600;
          z-index: 2;
          position: relative;
        }
        #sub-title-contact {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          span {
            z-index: 2;
            font-size: 2rem;
          }
          h4 {
            z-index: 2;
            font-weight: 400;
            font-size: 2rem;
            position: relative;
          }
        }
      }
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;
        max-width: 80%;
        margin: 0 auto;
        .styled-input {
          position: relative;
          left: -30px;
          width: 73%;
          margin: 1rem 0;
          textarea {
            min-height: 12rem;
            // resize: vertical;
          }
          input,
          textarea {
            padding: 30px;
            border: 0;
            width: 100%;
            font-size: 1rem;
            background-color: #2d2d2d;
            color: white;
            border-radius: 10px;
            &:focus {
              background-color: rgb(232, 240, 254);
              color: black;
              outline: 0;
            }
            &:focus ~ label {
              font-size: 0.75rem;
              top: -5px;
              -webkit-transition: font-size 0.225s ease;
              transition: font-size 0.225s ease;
            }
            & ~ label {
              color: #999;
              font-size: 1rem;
              padding: 1.3rem 30px 1rem 30px;
              position: absolute;
              top: 10px;
              left: 0;
              pointer-events: none;
              -webkit-transition: font-size 0.225s ease;
              transition: font-size 0.225s ease;
            }
          }
          input:-webkit-autofill,
          input:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
          }
        }
        #submit {
          .submit-btn {
            float: right;
            margin: 1rem 1.2rem;
            padding: 7px 35px;
            border-radius: 60px;
            display: inline-block;
            background-color: #4b8cfb;
            color: white;
            cursor: pointer;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06),
              0 2px 10px 0 rgba(0, 0, 0, 0.07);
            transform: translateY(0);
            -webkit-transition: transform 300ms ease;
            transition: transform 300ms ease;
          }
          .submit-btn:hover {
            transform: translateY(3px);
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1),
              0 1px 1px 0 rgba(0, 0, 0, 0.09);
          }
        }
      }
    }
  }
}

/**************** Mobile style *****************/
/**
* xs
**/
@media screen and (min-width: $breakpoint-min-xs) and (max-width: $breakpoint-max-xs) {
  #contact {
    padding-bottom: 0;
    #content-contact {
      #form-container {
        #title {
          h3 {
            font-size: 14vmin;
          }
        }
        #flowers-right {
          width: 35vmin;
        }
        #flowers-bg {
          width: 80vmin;
          left: -21%;
          bottom: calc(100% - 74rem);
          transform: rotateZ(357deg) rotateX(18deg);
        }
        form {
          max-width: 100%;
          #submit {
            .submit-btn {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
/**
* sm
**/
@media screen and (min-width: $breakpoint-min-sm) and (max-width: $breakpoint-max-sm) {
  #contact {
    #content-contact {
      #form-container {
        #title {
          h3 {
            font-size: 12vmin;
          }
        }
        #flowers-right {
          width: 35vmin;
        }
        #flowers-bg {
          width: 80vmin;
          left: -21%;
          bottom: calc(100% - 74rem);
          transform: rotateZ(357deg) rotateX(18deg);
        }
        form {
          max-width: 100%;
          #submit {
            .submit-btn {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
/**
* md
**/
@media screen and (min-width: $breakpoint-min-md) and (max-width: $breakpoint-max-md) {
  #contact {
    #content-contact {
      #form-container {
        #title {
          h3 {
            font-size: 12vmin;
          }
        }
        #flowers-right {
          width: 35vmin;
        }
        #flowers-bg {
          width: 80vmin;
          left: -21%;
          bottom: calc(100% - 74rem);
          transform: rotateZ(357deg) rotateX(18deg);
        }
        form {
          max-width: 100%;
          #submit {
            .submit-btn {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

/**************** Laptop style *****************/
@media screen and (min-width: $breakpoint-min-laptop) {
  #contact {
    #content-contact {
      #form-container {
        #title {
          h3 {
            font-size: 12vmin;
          }
        }
        #flowers-right {
          width: 35vmin;
        }
        #flowers-bg {
          width: 80vmin;
          left: -21%;
          bottom: calc(100% - 74rem);
          transform: rotateZ(357deg) rotateX(18deg);
        }
        form {
          max-width: 100%;
          #submit {
            .submit-btn {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

/**************** Desktop style *****************/
@media screen and (min-width: $breakpoint-min-desktop) {
  #contact {
    #content-contact {
      #form-container {
        #title {
          h3 {
            font-size: 10vmin;
          }
        }
        form {
          max-width: 80%;
          #submit {
            .submit-btn {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

/**************** 4k style *****************/
@media screen and (min-width: $breakpoint-min-4k) {
  #contact {
    #content-contact {
      #form-container {
        #title {
          h3 {
            font-size: 10vmin;
          }
        }
        form {
          max-width: 80%;
          #submit {
            .submit-btn {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}
