@import '../abstracts/variables';

#container-big-picture {
  position: relative;
  #big-picture-project {
    object-fit: cover;
    width: 100%;
    height: calc(100vh + 80px);
  }
  #scroll-down {
    height: 50px;
    width: 30px;
    border: 4px solid black;
    position: absolute;
    left: 50%;
    bottom: 120px;
    transform: translateX(-50%);
    border-radius: 50px;
    cursor: pointer;
    transition: opacity 0.2s ease;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 20px;
      left: 50%;
      border: 3px solid black;
      height: 10px;
      width: 10px;
      transform: translate(-50%, -100%) rotate(45deg);
      border-top: transparent;
      border-left: transparent;
      animation: scroll-down 1s ease-in-out infinite;
    }
    &:after {
      top: 30%;
      animation-delay: 0.2s;
    }
    &.is-hidden {
      opacity: 0;
    }
  }
  @keyframes scroll-down {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: 90%;
    }
  }
  #header-project {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.699);
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      height: 80px;
      h1 {
        text-transform: uppercase;
      }
      #icon-return {
        &:hover {
          color: darkseagreen;
        }
      }
      ol {
        li {
          display: inline-block;
          font-size: 1.5rem;
          & + :before {
            display: inline-block;
            padding-right: 10px;
            color: #0e2442;
            content: '/';
          }
        }
        li:first-of-type a {
          color: royalblue;
        }
      }
    }
  }
}
#content-project-details {
  margin: 0 auto;
  #infos-resume {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;
    min-height: 200px;
    #infos-project {
      background-color: darkseagreen;
      padding: 1rem;
      width: 35%;
      box-shadow: 2px 2px 1rem #00000059;
      position: relative;
      #container-infos-project {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        #title-infos-project {
          width: 100%;
          text-align: center;
          margin-bottom: 1rem;
          h3 {
            font-weight: 600;
            border-bottom: black solid 1px;
            padding-bottom: 1rem;
          }
        }
        ul li {
          font-weight: 100;
          strong {
            font-weight: 400;
          }
        }
      }
    }
    #sub-title-project {
      position: relative;
      width: 50%;
      h2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
  }
  #text-presentation-project {
    h4 {
      margin-top: 2rem;
      font-weight: bold;
    }
    padding: 3rem 0;
    position: relative;
    p {
      margin: 0 auto;
      font-weight: 100;
    }
    ul {
      margin: 0.5em;
      li {
        line-height: 1.3em;
        font-weight: 100;
        position: relative;
        list-style-type: none;
        padding-left: 1.5rem;
        margin-bottom: 1.5rem;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: -2px;
          width: 5px;
          height: 11px;
          border-width: 0 2px 2px 0;
          border-style: solid;
          border-color: #00a8a8;
          transform-origin: bottom left;
          transform: rotate(45deg);
        }
      }
    }
  }
}
#slide-show-project {
  position: relative;
  margin: auto;
  text-align: center;

  .radio-buttons {
    margin-bottom: 25px;
  }
}
/**************** small device style *****************/
@media screen and (min-width: $breakpoint-min-xs) {
  #container-big-picture {
    #header-project {
      div {
        width: 90vw;
        h1 {
          font-size: 2rem;
        }
        #icon-return {
          display: block;
          font-size: 2rem;
        }
        ol {
          display: none;
        }
      }
    }
  }
  #content-project-details {
    width: 80vw;
    #infos-resume {
      flex-direction: column-reverse;
      gap: 5rem;
      align-items: center;
      #infos-project {
        width: 100%;
        height: 250px;
        #container-infos-project {
          width: 90%;
          h3 {
            font-size: max(4.5vmin, 1.5rem);
          }
          ul li {
            font-size: max(3.2vmin, 1rem);
            margin-top: 1.2rem;
          }
        }
      }
      #sub-title-project {
        width: 100%;
        h2 {
          font-size: max(1.5vmin, 2rem);
        }
      }
    }
    #text-presentation-project {
      font-size: max(3.2vmin, 1rem);
      p {
        line-height: 7vmin;
        text-align: justify;
      }
    }
  }
  #slide-show-project {
    img {
      width: 100%;
      height: auto;

    }
  }
}

/**************** Laptop style *****************/
@media screen and (min-width: $breakpoint-min-laptop) {
  #container-big-picture {
    #header-project {
      div {
        width: 80vw;
        #icon-return {
          display: none;
        }
        ol {
          display: block;
        }
      }
    }
  }
  #content-project-details {
    width: 80vw;
    #infos-resume {
      flex-direction: column-reverse;
      gap: 5rem;
      align-items: center;
      #infos-project {
        width: 100%;
        height: 250px;
        #container-infos-project {
          width: 95%;
          h3 {
            font-size: 2.2rem;
          }
          ul li {
            font-size: 1.5rem;
            margin-top: 1rem;
          }
        }
      }
      #sub-title-project {
        width: 100%;
        h2 {
          font-size: max(1.5vmin, 2rem);
        }
      }
    }
    #text-presentation-project {
      font-size: 1.2rem;
      p {
        line-height: 2rem;
        text-align: justify;
      }
    }
  }
  #slide-show-project {
    img {
      width: 100%;
      height: auto;
    }
  }
}
/**************** Desktop style *****************/
@media screen and (min-width: $breakpoint-min-desktop) {
  #container-big-picture {
    #header-project {
      div {
        width: 75vw;
        h1 {
          font-size: max(2.8vmin, 1.5rem);
        }
        #icon-return {
          display: none;
        }
        ol {
          display: block;
          li {
            font-size: max(2.5vmin, 1.5rem);
          }
        }
      }
    }
  }
  #content-project-details {
    width: 75vw;
    #infos-resume {
      flex-direction: row;
      #infos-project {
        width: 100%;
        height: 250px;
        #container-infos-project {
          width: 95%;
          h3 {
            font-size: max(2.5vmin, 1.5rem);
          }
          ul li {
            font-size: max(1.4vmin, 1rem);
            margin-top: 0.8rem;
          }
        }
      }
      #sub-title-project {
        h2 {
          font-size: max(2.5vmin, 1.5rem);
        }
      }
    }
    #text-presentation-project {
      font-size: max(1.4vmin, 1rem);
      p {
        line-height: 2rem;
        text-align: justify;
      }
    }
  }
  #slide-show-project {
    img {
      width: 100%;
      height: auto;
    }
  }
}
