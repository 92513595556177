@import '../abstracts/variables';

#expertise {
  background-color: white;
  margin: 0 auto;
  padding-bottom: 7rem;
  #boxes-expertise {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    .box-expertise {
      min-width: 15rem;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
      z-index: 1;
      position: relative;
      .sous-titre {
        font-weight: bold;
      }
      p {
        padding: 1rem;
        font-weight: 100;
      }
      .wrapper-giff {
        // background-color: cornflowerblue;
        width: 100%;
        height: 6rem;
        z-index: 2;
        overflow: hidden;
        .giphy-embed {
          width: 100%;
          z-index: 3;
          opacity: 0.85;
        }
      }
      h3 {
        text-align: center;
        color: white;
        text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.77);
        font-weight: bold;
        min-height: 3rem;
        z-index: 4;
        top: 3rem;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        width: 100%;
      }
      ul {
        margin: 0.5em;
        li {
          line-height: 1.3em;
          font-weight: 100;
          position: relative;
          list-style-type: none;
          padding-left: 1.5rem;
          margin-bottom: 1.5rem;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: -2px;
            width: 5px;
            height: 11px;
            border-width: 0 2px 2px 0;
            border-style: solid;
            border-color: #00a8a8;
            transform-origin: bottom left;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  #datas-skills {
    padding: 4rem 0;
    display: none;
    p {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
  #slider {
    padding-top: 2rem;
    text-align: center;
    min-height: 30rem;
    #arrows {
      position: relative;
      height: 4rem;
      p {
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        z-index: 1;
      }
      button {
        cursor: pointer;
        all: unset;
        z-index: 2;
        &:nth-of-type(1) {
          z-index: 2;
          left: 25%;
          position: absolute;
          top: 35%;
        }
        &:nth-of-type(2) {
          z-index: 2;
          right: 25%;
          position: absolute;
          top: 35%;
        }
      }
    }
    #wrapper-carousel {
      padding-top: 2rem;
      min-height: 25rem;
      width: 100%;
      overflow: hidden;
      .content-carousel {
        width: 100%;
        height: 20rem;
        perspective: 1000px;
        transform-style: preserve-3d;
        position: relative;
        transform: translateY(15%);
        [type='radio'] {
          display: none;
        }
        label {
          margin: auto;
          width: 20%;
          border-radius: 10px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          cursor: pointer;
          transition: transform 0.4s ease;
          p {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
          &.bg {
            background: #555555;
            height: 100%;
            text-align: center;
            p {
              color: #3ee39b;
              text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.77);
              font-size: 1.3rem;
              font-weight: bold;
              top: -3.8rem;
              width: 120%;
            }
          }
          &.data {
            background: #3ee39b;
            p {
              color: white;
              padding-top: 1rem;
              font-size: 2.5vmin;
            }
          }
          &.foundation {
            height: 33%;
          }
          &.intermediate {
            height: 66%;
          }
          &.advanced {
            height: 100%;
          }
        }

        $totalLabels: 43;
        $totalIterationsSide: calc(($totalLabels - 1) / 2);

        @for $indexItemsLeft from 1 through $totalIterationsSide {
          $basePosition: 0;
          @if ($indexItemsLeft==1) {
            $basePosition: -135;
          } @else {
            $basePosition: -135 + (-160 * ($indexItemsLeft - 1));
          }
          @for $i from 0 through ($totalLabels - 1) {
            @if $i >= $indexItemsLeft {
              $label: $i - $indexItemsLeft;
              .s#{$i}:checked ~ .slide#{$label} {
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3),
                  0 2px 2px 0 rgba(0, 0, 0, 0.2);
                transform: translate3d(
                  $basePosition + 0%,
                  0,
                  (-200 * $indexItemsLeft) + 0px
                );
                opacity: 0.75;
              }
            } @else {
              $label: $i + ($totalLabels - $indexItemsLeft);
              .s#{$i}:checked ~ .slide#{$label} {
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3),
                  0 2px 2px 0 rgba(0, 0, 0, 0.2);
                transform: translate3d(
                  $basePosition + 0%,
                  0,
                  (-200 * $indexItemsLeft) + 0px
                );
                opacity: 0.75;
              }
            }
          }
        }

        @for $i from 0 through 4 {
          .s#{$i}:checked ~ .slide#{$i},
          .s#{$i}:checked ~ .slide#{$i},
          .s#{$i}:checked ~ .slide#{$i},
          .s#{$i}:checked ~ .slide#{$i},
          .s#{$i}:checked ~ .slide#{$i} {
            box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.3),
              0 11px 7px 0 rgba(0, 0, 0, 0.19);
          }
        }

        @for $indexItemsRight from 1 through $totalIterationsSide {
          $basePosition: 0;
          @if ($indexItemsRight==1) {
            $basePosition: 135;
          } @else {
            $basePosition: 135 + (160 * ($indexItemsRight - 1));
          }

          @for $i from 0 through ($totalLabels - 1) {
            @if $i >= ($totalLabels - $indexItemsRight) {
              $label: $i - ($totalLabels - $indexItemsRight);
              .s#{$i}:checked ~ .slide#{$label} {
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3),
                  0 2px 2px 0 rgba(0, 0, 0, 0.2);
                transform: translate3d(
                  $basePosition + 0%,
                  0,
                  (-200 * $indexItemsRight) + 0px
                );
                opacity: 0.75;
              }
            } @else {
              $label: $i + $indexItemsRight;
              .s#{$i}:checked ~ .slide#{$label} {
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3),
                  0 2px 2px 0 rgba(0, 0, 0, 0.2);
                transform: translate3d(
                  $basePosition + 0%,
                  0,
                  (-200 * $indexItemsRight) + 0px
                );
                opacity: 0.75;
              }
            }
          }
        }
      }
    }
  }
}

/**************** Mobile style *****************/
/**
* xs
**/
@media screen and (min-width: $breakpoint-min-xs) and (max-width: $breakpoint-max-xs) {
  #expertise {
    #boxes-expertise {
      gap: 1rem;
      grid-template-columns: 1fr;
      .box-expertise {
        border-bottom: 0.2em solid steelblue;
        margin: 1rem auto;
        min-width: 14rem;
        max-width: 18rem;
        h3 {
          font-size: 2rem;
        }
        p {
          padding: 2rem;
          font-size: 3.5vmin;
          line-height: 1.5rem;
        }
        ul {
          li {
            font-size: 0.9rem;
          }
        }
        .wrapper-giff {
          height: 10rem;
          .giphy-embed {
            margin-top: -2rem;
            height: 16rem;
          }
        }
      }
    }
    #slider {
      h3 {
        font-size: 1.5rem;
      }
    }
  }
}
/**
* sm
**/
@media screen and (min-width: $breakpoint-min-sm) and (max-width: $breakpoint-max-sm) {
  #expertise {
    #boxes-expertise {
      gap: 1rem;
      grid-template-columns: 1fr;
      .box-expertise {
        border-bottom: 0.2em solid steelblue;
        margin: 2rem auto;
        min-width: 14rem;
        max-width: 18rem;
        h3 {
          font-size: 2rem;
        }
        p {
          padding: 2rem;
          font-size: 3.5vmin;
          line-height: 1.5rem;
        }
        ul {
          li {
            font-size: 0.9rem;
          }
        }
        .wrapper-giff {
          height: 10rem;
          .giphy-embed {
            margin-top: -2rem;
            height: 16rem;
          }
        }
      }
    }
    #slider {
      h3 {
        font-size: 1.5rem;
      }
    }
  }
}
/**
* md
**/
@media screen and (min-width: $breakpoint-min-md) and (max-width: $breakpoint-max-md) {
  #expertise {
    #boxes-expertise {
      .box-expertise {
        border-bottom: 0.2em solid steelblue;
        margin: 2rem auto;
        width: 23rem;
        h3 {
          font-size: 2.4rem;
        }
        p {
          padding: 2rem;
          font-size: 3vmin;
          line-height: 1.5rem;
        }
        .wrapper-giff {
          height: 10rem;
          .giphy-embed {
            margin-top: -5em;
            height: 20rem;
          }
        }
      }
    }
    #slider {
      h3 {
        font-size: 1.5rem;
      }
    }
  }
}

/**************** Laptop style *****************/
@media screen and (min-width: $breakpoint-min-laptop) and (max-width: $breakpoint-max-laptop) {
  #expertise {
    #boxes-expertise {
      .box-expertise {
        border-bottom: 0.4em solid steelblue;
        margin: 2rem auto;
        width: 23rem;
        h3 {
          font-size: 3rem;
        }
        p {
          padding: 2rem;
          font-size: 1.1;
          line-height: 2.2rem;
        }
        .wrapper-giff {
          height: 12rem;
          .giphy-embed {
            height: 25rem;
            margin-top: -8rem;
          }
        }
      }
    }
    #datas-skills {
      display: block;
    }
    #slider {
      display: none;
    }
  }
}

/**************** 4k style *****************/
@media screen and (min-width: $breakpoint-min-4k) {
  #expertise {
    #boxes-expertise {
      font-size: 1.2rem;
      .box-expertise {
        border-bottom: 0.4em solid steelblue;
        margin: 2rem auto;
        max-width: 26rem;
        h3 {
          font-size: 3rem;
        }
        p {
          padding: 2rem;
          font-size: 1.1;
          line-height: 2.2rem;
        }
        .wrapper-giff {
          height: 12rem;
          .giphy-embed {
            height: 25rem;
            margin-top: -8rem;
          }
        }
      }
    }
    #donut {
      padding-top: 3.75rem;
      #datas-skills {
        display: block;
        max-width: 75vmin;
        margin: 0 auto;
      }
    }
    #slider {
      display: none;
    }
  }
}

/**************** Desktop style *****************/
@media screen and (min-width: $breakpoint-min-desktop) {
  #expertise {
    #boxes-expertise {
      font-size: 1.2rem;
      .box-expertise {
        border-bottom: 0.4em solid steelblue;
        margin: 2rem auto;
        max-width: 26rem;
        h3 {
          font-size: 3rem;
        }
        p {
          padding: 2rem;
          font-size: 1.1;
          line-height: 2.2rem;
        }
        .wrapper-giff {
          height: 12rem;
          .giphy-embed {
            height: 25rem;
            margin-top: -8rem;
          }
        }
      }
    }
    #donut {
      padding-top: 3.75rem;
      #datas-skills {
        display: block;
        max-width: 75vmin;
        margin: 0 auto;
      }
    }
    #slider {
      display: none;
    }
  }
}
