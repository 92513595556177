@import '../abstracts/variables';

#experience {
  background-color: white;
  padding-bottom: 7rem;
  #boxes-experience {
    min-height: 30rem;
    padding: 4rem 0;
    display: grid;
    justify-content: center;
    .box-experience {
      background-color: white;
      padding: 0 2rem;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
      -moz-transition: height 1s ease;
      -webkit-transition: height 1s ease;
      -o-transition: height 1s ease;
      transition: height 1s ease;
      .title-project {
        p {
          text-shadow: rgb(255, 255, 255) 2px 0 1px;
        }
      }
      &.is-active {
        transition: height 0.5s;
        .content-card-experience {
          opacity: 1;
        }
        .btn-project-details {
          visibility: visible !important;
        }
        .imgbox {
          .title-project {
            p {
              opacity: 0;
            }
          }
        }
        
        .tagsbox {
          visibility: hidden;
          display: none;
        }
      }
      .imgbox {
        text-align: center;
        position: relative;
        left: 50%;
        transform: translateY(-15%) translateX(-50%);
        color: #111;
        z-index: 2;
        img {
          border-radius: 10px;
        }
        img:hover{
          cursor: pointer;
        }
        p {
          width: 100%;
          bottom: 1%;
          left: 50%;
          transform: translate(-50%, -30%);
          position: absolute;
          font-weight: 800;
          text-transform: uppercase;
          transition: height 0.3s;
        }

        .btn-project-details {
          z-index: 3;
          background-color: #9b87eb70;
          bottom: 2px;
          position: absolute;
          width: 100%;
          height: 50px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          color: white;
          visibility: hidden;
        }
      }
      .tagsbox {
        width: 100%;
        height: 15%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 10px;
        display: flex;
        visibility: visible;
        align-items: center;
        transition: visibility 0.1s;
        .tag {
          color: #ffffff;
          background: #b8b8b8;
          text-shadow: 1px 1px 0px #7a7a7a;
          font-weight: 500;
          margin: 0.2rem 0.4rem;
          display: flex;
          align-items: center;
          height: 0.8rem;
        }
      }
      .content-card-experience {
        min-height: 8rem;
        text-align: center;
        opacity: 0;
        color: #111;
        transition: transform 0.5s, opacity 0.1s;
        transform: translate(-50%, -50%);
        position: absolute;
        top: calc(120% - 9rem);
        left: 50%;
        width: 100%;
        h3 {
          margin: 1rem 0;
          font-weight: 800;
          text-transform: uppercase;
        }
        p {
          font-weight: lighter;
        }
      }
    }
  }
}

/**************** Mobile style *****************/
/**
* xs
**/
@media screen and (min-width: $breakpoint-min-xs) and (max-width: $breakpoint-max-xs) {
  #experience {
    #boxes-experience {
      min-height: 20rem;
      gap: 10rem 2rem;
      grid-template-columns: 1fr;
      grid-auto-rows: 18rem;
      .box-experience {
        width: 13rem;
        height: 17rem;
        &.is-active {
          height: 23rem;
        }
        .imgbox {
          width: 15rem;
          img {
            width: 15rem;
            height: auto;
          }
          p {
            font-size: 0.9rem;
          }
        }
        .tagsbox {
          .tag {
            border-radius: 10px;
            padding: 0.4rem;
            font-size: 2.3vmin;
          }
        }
        .content-card-experience {
          top: 17rem;
          h3 {
            font-size: 0.9rem;
          }
          p {
            font-size: 0.8rem;
            padding: 0 1.2rem;
          }
        }
      }
    }
  }
}
/**
* sm
**/
@media screen and (min-width: $breakpoint-min-sm) and (max-width: $breakpoint-max-sm) {
  #experience {
    #boxes-experience {
      min-height: 20rem;
      gap: 10rem 2rem;
      grid-template-columns: 1fr;
      grid-auto-rows: 18rem;
      .box-experience {
        width: 15rem;
        height: 18.5rem;
        &.is-active {
          height: 24rem;
        }
        .imgbox {
          width: 17rem;
          img {
            width: 17rem;
            height: auto;
          }
          p {
            font-size: 0.8rem;
          }
        }
        .tagsbox {
          .tag {
            border-radius: 10px;
            padding: 0.4rem;
            font-size: 2vmin;
          }
        }
        .content-card-experience {
          top: 20rem;
          h3 {
            font-size: 0.9rem;
          }
          p {
            font-size: 0.8rem;
            padding: 0 1.2rem;
          }
        }
      }
    }
  }
}
/**
* md
**/
@media screen and (min-width: $breakpoint-min-md) and (max-width: $breakpoint-max-md) {
  #experience {
    #boxes-experience {
      min-height: 20rem;
      gap: 10rem 2rem;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 14rem;
      .box-experience {
        width: 11rem;
        height: 14rem;
        &.is-active {
          height: 21rem;
        }
        .imgbox {
          width: 12rem;
          img {
            width: 12rem;
            height: auto;
          }
          p {
            font-size: 0.8rem;
          }
        }
        .tagsbox {
          .tag {
            border-radius: 10px;
            padding: 0.4rem;
            font-size: 1.2vmin;
          }
        }
        .content-card-experience {
          top: 15rem;
          h3 {
            font-size: 0.9rem;
          }
          p {
            font-size: 0.8rem;
            padding: 0 1.2rem;
          }
        }
      }
    }
  }
}
/**************** Laptop style *****************/
@media screen and (min-width: $breakpoint-min-laptop) and (max-width: $breakpoint-max-laptop) {
  #experience {
    #boxes-experience {
      gap: 15rem;
      grid-template-columns: 1fr;
      grid-auto-rows: 20rem;
      .box-experience {
        width: 18rem;
        height: 20rem;
        &.is-active {
          height: 30rem;
        }
        .imgbox {
          max-width: 20rem;
          img {
            width: 18rem;
            height: auto;
          }
          p {
            font-size: 1.5rem;
          }
        }
        .tagsbox {
          padding-left: 1rem;
          .tag {
            border-radius: 18px;
            padding: 0.8rem;
            font-size: 1.2vmax;
          }
        }
        .content-card-experience {
          top: calc(102% - 8.3rem);
          h3 {
            font-size: 1.5rem;
            padding-top: 0.3rem;
          }
          p {
            font-size: 1.1rem;
            padding: 0.2rem 1.2rem 1.2rem;
          }
        }
      }
    }
  }
}

/**************** Desktop style *****************/
@media screen and (min-width: $breakpoint-min-desktop) {
  #experience {
    #boxes-experience {
      gap: 15rem 2rem;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 20rem;
      .box-experience {
        width: 18rem;
        height: 20rem;
        &.is-active {
          height: 30rem;
        }
        .imgbox {
          max-width: 20rem;
          img {
            width: 18rem;
            height: auto;
          }
          p {
            font-size: 1.5rem;
          }
        }
        .tagsbox {
          padding-left: 1rem;
          .tag {
            border-radius: 18px;
            padding: 0.8rem;
            font-size: 0.8vmax;
          }
        }
        .content-card-experience {
          top: calc(102% - 8.3rem);
          h3 {
            font-size: 1.5rem;
            padding-top: 0.3rem;
          }
          p {
            font-size: 1.3rem;
            padding: 0.2rem 1.2rem 1.2rem;
          }
        }
      }
    }
  }
}

/**************** 4k style *****************/
@media screen and (min-width: $breakpoint-min-4k) {
  #experience {
    #boxes-experience {
      gap: 9.2rem 4rem;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 30rem;
      .box-experience {
        width: 28rem;
        height: 30rem;
        &.is-active {
          height: 35rem;
        }
        .imgbox {
          max-width: 25rem;
          img {
            width: 25rem;
            height: auto;
          }
          p {
            font-size: 1.5rem;
          }
        }
        .tagsbox {
          padding-left: 1rem;
          .tag {
            border-radius: 18px;
            padding: 0.8rem;
            font-size: 1.2rem;
          }
        }
        .content-card-experience {
          top: calc(102% - 8.3rem);
          h3 {
            font-size: 1.5rem;
            padding-top: 0.3rem;
          }
          p {
            font-size: 1.3rem;
            padding: 0.2rem 1.2rem 1.2rem;
          }
        }
      }
    }
  }
}
